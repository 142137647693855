<template>
    <iq-card class-name="iq-card-block iq-card-stretch iq-card-height">
      <loader v-if="!isContentLoaded" :is-visible="!isContentLoaded"></loader>
      <div v-if="isContentLoaded">
        <div class="iq-card-header d-flex justify-content-between">
          <apex-chart-header v-if="header && header.length > 0" :disable-range-selection="true" :header="header"></apex-chart-header>
        </div>
        <div class="card-body iq-card-body p-1" style="position: relative;">
          <apexchart v-if="series && series.length > 0" type="heatmap" :height="customHeight" :options="chartOptions" :series="series"></apexchart>

          <div v-else role="alert" aria-live="polite" aria-atomic="true" class="alert alert-danger mx-2">
            <div class="iq-alert-icon">
              <i class="fa-regular fa-triangle-exclamation"></i>
            </div>
            <div class="iq-alert-text">
              <b style="font-weight: 500;">{{ $t('Reportings.AppointmentHeatmap.WarningMessage') }}</b>
            </div>
          </div>
        </div>
      </div>
    </iq-card>
</template>

<script>
  import Loader from "../../components/shared/Loader";
  import ApexChartHeader from "../../components/apexCharts/ApexChartHeader";

  import ChartService from "../../services/chart";
  import "../../plugins/apexchart";

  export default {
    components: {
      Loader,
      ApexChartHeader
    },
    props: {
      dataUrl: String,
      isSmartDuo: Boolean,
      dataObject: Object
    },
    data() {
      return {
        header: '',
        date: { startDate: '', endDate: '' },
        customHeight: 350,
        isContentLoaded: false,
        series: [{}],
        chartOptions: {
          chart: {
            height: this.customHeight,
            type: 'heatmap',
            toolbar: {
              show: true,
              offsetX: 0,
              offsetY: 0,
              tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
              }
            },
            defaultLocale: 'tr',
            locales: [{
              name: 'tr',
              options: {
                toolbar: {
                  "exportToSVG": this.$t('DownloadSvg'),
                  "exportToPNG": this.$t('DownloadPng'),
                  "menu": this.$t('Menu'),
                  "selection": this.$t('Selection'),
                  "selectionZoom": this.$t('SelectionZoom'),
                  "zoomIn": this.$t('ZoomIn'),
                  "zoomOut": this.$t('zoomOut'),
                  "pan": this.$t('Panning'),
                  "reset": this.$t('ResetZoom')
                }
              }
            }]
          },
          plotOptions: {
            heatmap: {
              shadeIntensity: 0.5,
              radius: 0,
              useFillColorAsStroke: true
            }
          },
          colors: ["#008FFB"],
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: 1
          }
        }
      }
    },
    methods: {
      GetAppointmentCountsHeatmapGrouppedByDays: function () {
        ChartService.getChartData(this.chartDataUrl)
          .then(response => {
            if (response) {
              this.setData(response);
            }
          });
      },
      setData(filledChartData) {
        this.series = filledChartData.series;
        this.header = this.$t(filledChartData.title);
        if (this.series.length > 10)
          this.customHeight = "";
        this.isContentLoaded = true;
      }
    },
    watch: {
      chartDataUrl: {
        handler: function () {
          this.GetAppointmentCountsHeatmapGrouppedByDays()
        },
        deep: true
      },
      chartData: function () {
        this.setData(this.chartData);
      },

    },
    computed: {
      chartDataUrl: function () {
        return this.dataUrl;
      },
      chartData: function () {
        return this.dataObject;
      },
    },
    mounted: function () {
    }
  }
</script>

<style lang="scss" scoped>
    ::v-deep .apexcharts-menu-item.exportCSV {
        display: none;
    }

    ::v-deep .apexcharts-menu.apexcharts-menu-open {
        min-width: 82px !important;
    }
</style>
